/* eslint-disable react-hooks/exhaustive-deps */

import React, { useState } from 'react';
import View from '../View';
import * as Styles from './Styles.module.scss';
import Animated from '../Animated';
import { Link } from 'gatsby';
import classnames from 'classnames';
import Flippy, { FrontSide, BackSide } from 'react-flippy';

interface IProductItem {
    imageSource: string;
    link?: string;
    label?: string;
    onClick?: () => void;
    className?: string;
    backContent?: string;
}

const ProductItem: React.FC<IProductItem> = ({
    imageSource,
    link,
    label,
    onClick,
    className,
    backContent,
}) => {
    const [isFlipped, setIsFlipped] = useState<boolean>(false);

    const renderImage = () => {
        return imageSource ? (
            <Flippy
                flipOnHover={backContent !== undefined}
                flipOnClick={false}
                flipDirection="horizontal"
                style={{ width: '320px', height: '213px' }}>
                <FrontSide className={Styles.frontBack}>
                    <img
                        src={imageSource}
                        style={{
                            objectFit: 'cover',
                        }}
                        className={Styles.image}
                        alt=""
                    />
                    {label ? (
                        <span className={Styles.label}>{label}</span>
                    ) : (
                        <></>
                    )}
                </FrontSide>
                <BackSide className={classnames(Styles.frontBack, Styles.back)}>
                    <strong style={{ fontSize: '1.2rem' }}>{label}</strong>
                    <br />
                    <br />
                    {backContent}
                </BackSide>
            </Flippy>
        ) : (
            <></>
        );
    };

    return (
        <View
            className={classnames(Styles.mainContainer, className || '')}
            onMouseEnter={event => {
                event.preventDefault();
                setIsFlipped(true);
            }}
            onMouseLeave={() => {
                setIsFlipped(false);
            }}>
            <Animated animation="fadeIn">
                {link ? (
                    <Link to={link}>{renderImage()}</Link>
                ) : (
                    <a
                        href="/#"
                        onClick={event => {
                            event.preventDefault();
                            if (onClick) onClick();
                        }}>
                        {renderImage()}
                    </a>
                )}
            </Animated>
        </View>
    );
};

export default ProductItem;
