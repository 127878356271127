import React from 'react';

interface ISpace {
    height?: number;
    width?: number;
}

const Space: React.FC<ISpace> = props => {
    const { height, width } = props;

    return (
        <div
            style={{
                width: !width ? '100%' : undefined,
                height: !height ? '100%' : undefined,
                paddingTop: height ? height / 2 : undefined,
                paddingBottom: height ? height / 2 : undefined,
                paddingLeft: width ? width / 2 : undefined,
                paddingRight: width ? width / 2 : undefined,
            }}
        />
    );
};

export default Space;
