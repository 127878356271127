/* eslint-disable react-hooks/exhaustive-deps */

import React, { Fragment } from 'react';
import { View, Space } from 'src/Widgets';
import * as Styles from './Styles.module.scss';
import { Container, Row, Col } from 'react-bootstrap';
import { useIntl } from 'gatsby-plugin-intl';
import classnames from 'classnames';
import { Link } from 'gatsby';
import { IMenuLink } from 'src/Types/GeneralTypes';
import Icon from '@mdi/react';
import { mdiPhone, mdiEmail, mdiMapMarker } from '@mdi/js';
import { Colors } from 'src/Resources';

const ICON_SIZE = 20;

interface IFooter {}

const Footer: React.FC<IFooter> = () => {
    const intl = useIntl();

    const quickLinks: IMenuLink[] = [
        {
            text: intl.formatMessage({ id: 'menu.products' }),
            link: `/${intl.locale}/products`,
        },
        {
            text: intl.formatMessage({ id: 'menu.about' }),
            link: `/${intl.locale}/about`,
        },
        {
            text: intl.formatMessage({ id: 'menu.contact' }),
            link: `/${intl.locale}/contact`,
        },
        {
            text: intl.formatMessage({ id: 'menu.privacy' }),
            link: `/${intl.locale}/privacy-policy`,
        },
    ];

    return (
        <View className={Styles.mainContainer}>
            <Container>
                <Row>
                    {/* <Col md={4} sm={12} className={Styles.column}>
                        <span className={Styles.columnTitle}>
                            {intl.formatMessage({ id: 'footer.column1.title' })}
                        </span>

                        <Space height={24} />

                        <span className={Styles.columnDescription}>
                            {intl.formatMessage({
                                id: 'footer.column1.description',
                            })}
                        </span>
                    </Col> */}
                    <Col md={4} sm={12} className={Styles.column}>
                        <span className={Styles.columnTitle}>
                            {intl.formatMessage({ id: 'footer.column2.title' })}
                        </span>

                        <Space height={24} />

                        {quickLinks.map((element, index) => {
                            return (
                                <Fragment key={index.toString()}>
                                    <Link
                                        to={element.link}
                                        className={Styles.quickLink}>
                                        {element.text}
                                    </Link>
                                    {index < quickLinks.length - 1 ? (
                                        <Space height={12} />
                                    ) : (
                                        <></>
                                    )}
                                </Fragment>
                            );
                        })}
                    </Col>
                    <Col md={4} sm={12} className={Styles.column}></Col>
                    <Col md={4} sm={12} className={Styles.column}>
                        <span className={Styles.columnTitle}>
                            {intl.formatMessage({ id: 'footer.column3.title' })}
                        </span>

                        <Space height={12} />

                        <View className={Styles.contactDetailsContainer}>
                            <Icon
                                path={mdiPhone}
                                color={Colors.lightWhite}
                                style={{ width: ICON_SIZE, height: ICON_SIZE }}
                            />
                            <a
                                href={intl.formatMessage({
                                    id: 'footer.phoneLink',
                                })}
                                className={Styles.contactDetailsText}>
                                {intl.formatMessage({
                                    id: 'footer.phone',
                                })}
                            </a>
                        </View>

                        <View className={Styles.contactDetailsContainer}>
                            <Icon
                                path={mdiEmail}
                                color={Colors.lightWhite}
                                style={{ width: ICON_SIZE, height: ICON_SIZE }}
                            />
                            <a
                                href={intl.formatMessage({
                                    id: 'footer.emailAddressLink',
                                })}
                                className={Styles.contactDetailsText}>
                                {intl.formatMessage({
                                    id: 'footer.emailAddress',
                                })}
                            </a>
                        </View>

                        <View className={Styles.contactDetailsContainer}>
                            <Icon
                                path={mdiMapMarker}
                                color={Colors.lightWhite}
                                style={{ width: ICON_SIZE, height: ICON_SIZE }}
                            />
                            <span className={Styles.contactDetailsTextNoLink}>
                                {intl.formatMessage({
                                    id: 'footer.addressFull',
                                })}
                            </span>
                        </View>
                    </Col>
                </Row>

                <Space height={72} />

                <Row>
                    <Col
                        md={12}
                        className={classnames(Styles.column, 'text-center')}>
                        <span className={Styles.copyright}>
                            {intl.formatMessage(
                                { id: 'footer.copyright' },
                                { year: new Date().getFullYear() },
                            )}
                        </span>
                    </Col>
                </Row>
            </Container>
        </View>
    );
};

export default Footer;
