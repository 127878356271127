/* eslint-disable react-hooks/exhaustive-deps */

import React, { useEffect, useState, useRef } from 'react';
import classnames from 'classnames';
import uuidV4 from 'uuid/v4';

interface IAnimated {
    animation: string;
    delay?: number;
    className?: string;
    onAnimationEnd?: () => void;
}

const Animated: React.FC<IAnimated> = props => {
    const { animation, delay, className, children, onAnimationEnd } = props;

    const elementId = useRef<string>(uuidV4());

    const [mounted, setMounted] = useState<boolean>(false);

    useEffect(() => {
        const node = document.getElementsByClassName(
            `prefix_${elementId.current}`,
        )[0];

        node?.addEventListener(
            'animationend webkitAnimationEnd oAnimationEnd MSAnimationEnd',
            handleAnimationEnd,
            {
                once: true,
            },
        );

        setTimeout(() => {
            setMounted(true);
        }, delay || 200);

        return () => {
            node?.removeEventListener(
                'animationend webkitAnimationEnd oAnimationEnd MSAnimationEnd',
                handleAnimationEnd,
            );
        };
    }, []);

    const handleAnimationEnd = (event: any) => {
        event.stopPropagation();
        if (onAnimationEnd) onAnimationEnd();
    };

    return mounted ? (
        <div
            className={classnames(
                `prefix_${elementId.current}`,
                'animate__animated',
                `animate__${animation}`,
                className || '',
            )}
            children={children}
        />
    ) : (
        <></>
    );
};

export default Animated;
