/* eslint-disable react-hooks/exhaustive-deps */

import React from 'react';
import * as Styles from './Styles.module.scss';
import classnames from 'classnames';

declare interface IView {
    style?: React.CSSProperties;
    className?: string;
    dim?: boolean;
    onMouseEnter?:
        | ((event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void)
        | undefined;
    onMouseLeave?:
        | ((event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void)
        | undefined;
}

const View: React.FC<IView> = ({
    style,
    className,
    dim,
    onMouseEnter,
    onMouseLeave,
    children,
}) => {
    return (
        <div
            className={classnames(Styles.mainContainer, className)}
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
            style={style}>
            {children}
            {dim ? <div className={Styles.overlay} /> : <></>}
        </div>
    );
};

View.defaultProps = {
    style: {},
    className: '',
};

export default View;
