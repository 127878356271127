/* eslint-disable react-hooks/exhaustive-deps */

import React, { useState, Fragment } from 'react';
import * as Styles from './Styles.module.scss';
import { Col, Row, Container } from 'react-bootstrap';
import { View, FadeTransition, Space } from 'src/Widgets';
import { Link, navigate } from 'gatsby';
import { useIntl } from 'gatsby-plugin-intl';
import Icon from '@mdi/react';
import { mdiMenu, mdiClose } from '@mdi/js';
import { IMenuLink } from 'src/Types/GeneralTypes';
import { StaticImage } from 'gatsby-plugin-image';

interface IMenu {}

const Menu: React.FC<IMenu> = () => {
    const intl = useIntl();

    const [mobileMenuVisible, setMobileMenuVisible] = useState<boolean | null>(
        false,
    );

    const menuItems: IMenuLink[] = [
        {
            text: intl.formatMessage({ id: 'menu.home' }),
            link: `/${intl.locale}/`,
        },
        {
            text: intl.formatMessage({ id: 'menu.products' }),
            link: `/${intl.locale}/products`,
        },
        {
            text: intl.formatMessage({ id: 'menu.about' }),
            link: `/${intl.locale}/about`,
        },
        {
            text: intl.formatMessage({ id: 'menu.contact' }),
            link: `/${intl.locale}/contact`,
        },
    ];

    return (
        <View className={Styles.mainContainer}>
            <Container className={Styles.container}>
                <View className={Styles.containerOverlay} />
                <a
                    href="#"
                    onClick={event => {
                        event.preventDefault();
                        window.location.href = `/${
                            intl.locale === 'en' ? 'de' : 'en'
                        }/`;
                    }}
                    className={Styles.flag}>
                    {intl.locale === 'en' ? (
                        <StaticImage
                            src={'../../Resources/Images/deFlag.png'}
                            alt="German"
                        />
                    ) : (
                        <StaticImage
                            src={'../../Resources/Images/enFlag.png'}
                            alt="English"
                        />
                    )}
                </a>
                <Row>
                    <Col md={3} xs={12} className={Styles.logoContainer}>
                        <StaticImage
                            src={'../../Resources/Images/bonmea-icon.png'}
                            className={Styles.logo}
                            alt=""
                        />
                    </Col>
                    <Col md={1} className="d-none d-md-block"></Col>
                    <Col md={8} className="d-none d-md-block">
                        <View className={Styles.menuItemsContainer}>
                            {menuItems.map((element, index) => {
                                return (
                                    <Fragment key={index.toString()}>
                                        <Link
                                            to={element.link}
                                            className={Styles.menuItem}>
                                            {element.text}
                                        </Link>
                                        {index < menuItems.length - 1 ? (
                                            <Space width={24} />
                                        ) : (
                                            <></>
                                        )}
                                    </Fragment>
                                );
                            })}
                        </View>
                    </Col>
                </Row>
                <a
                    href="#"
                    onClick={() => setMobileMenuVisible(true)}
                    className={Styles.hamburgerIcon}>
                    <Icon path={mdiMenu} size={1} color="white" />
                </a>
            </Container>
            {mobileMenuVisible ? (
                <FadeTransition>
                    <View className={Styles.mobileMenu}>
                        {menuItems.map((element, index) => {
                            return (
                                <Fragment key={index.toString()}>
                                    <a
                                        href="#"
                                        className={Styles.menuItemMobile}
                                        onClick={() => {
                                            setMobileMenuVisible(false);
                                            setTimeout(() => {
                                                navigate(element.link);
                                            }, 500);
                                        }}>
                                        {element.text}
                                    </a>
                                    {index < menuItems.length - 1 ? (
                                        <Space height={48} />
                                    ) : (
                                        <></>
                                    )}
                                </Fragment>
                            );
                        })}
                        <a
                            href="#"
                            onClick={() => setMobileMenuVisible(false)}
                            className={Styles.closeIcon}>
                            <Icon path={mdiClose} size={1} color="#ed4848" />
                        </a>
                    </View>
                </FadeTransition>
            ) : (
                <></>
            )}
        </View>
    );
};

export default Menu;
