import React from 'react';
import 'src/Resources/Styles.scss';
import 'src/Resources/Animations.scss';
import { Helmet } from 'react-helmet';
import Footer from './Footer';
import Menu from './Menu';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { GlobalConfig } from 'src/Configuration';

interface ILayout {}

const Layout: React.FC<ILayout> = ({ children }) => {
    return (
        <GoogleReCaptchaProvider reCaptchaKey={GlobalConfig.reCAPTCHASiteKey}>
            <Helmet>
                <link
                    href="//fonts.googleapis.com/css2?family=Quicksand:wght@300;400;500;600;700&display=swap"
                    rel="stylesheet"></link>
            </Helmet>
            <Menu />
            {children}
            <Footer />
        </GoogleReCaptchaProvider>
    );
};

export default Layout;
