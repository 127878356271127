import React, { useEffect, useState } from 'react';
import { Transition } from 'react-transition-group';

interface IFadeTransition {
    key?: string | number | null | undefined;
    isSwitch?: boolean;
}

const FadeTransition: React.FC<IFadeTransition> = props => {
    const [animationStarted, setAnimationStarted] = useState<boolean>(false);

    useEffect(() => {
        setTimeout(() => {
            setAnimationStarted(true);
        }, 100);
    }, []);

    const duration = 300;

    const defaultStyle = {
        transition: `opacity ${duration}ms ease-in-out`,
    };

    const transitionStyles = {
        entering: { opacity: 1 },
        entered: { opacity: 1 },
        exiting: { opacity: 1 },
        exited: { opacity: 0 },
        unmounted: { opacity: 0 },
    };

    return (
        <Transition
            key={props.key}
            timeout={duration}
            in={animationStarted}
            isSwitch={props.isSwitch}>
            {state => (
                <div
                    style={{
                        ...defaultStyle,
                        ...transitionStyles[state],
                    }}>
                    {props.children}
                </div>
            )}
        </Transition>
    );
};

export default FadeTransition;
