/* eslint-disable react-hooks/exhaustive-deps */

import React, { useState } from 'react';
import View from '../View';
import * as Styles from './Styles.module.scss';
import { useStaticQuery, graphql } from 'gatsby';
import { useIntl } from 'gatsby-plugin-intl';
import BackgroundSlider from 'gatsby-image-background-slider';
import Animated from '../Animated';

interface IHomeSlider {}

const HomeSlider: React.FC<IHomeSlider> = () => {
    const intl = useIntl();

    const [currentIndex, setCurrentIndex] = useState<number>(0);

    const renderCaption = () => {
        return (
            <View
                key={currentIndex.toString()}
                className={Styles.captionContainer}>
                <Animated className={Styles.title} animation="fadeInDown">
                    {intl.formatMessage({
                        id: `home.slide${currentIndex + 1}.title`,
                    })}
                </Animated>
                <Animated className={Styles.subtitle} animation="fadeIn">
                    {intl.formatMessage({
                        id: `home.slide${currentIndex + 1}.subtitle`,
                    })}
                </Animated>
            </View>
        );
    };

    return (
        <View className={Styles.mainContainer}>
            <BackgroundSlider
                query={useStaticQuery(graphql`
                    query {
                        backgrounds: allFile(
                            filter: {
                                relativeDirectory: { eq: "Images/Home/Slides" }
                            }
                        ) {
                            nodes {
                                relativePath
                                childImageSharp {
                                    fluid(maxWidth: 1024, quality: 75) {
                                        ...GatsbyImageSharpFluid_noBase64
                                    }
                                }
                            }
                        }
                    }
                `)}
                initDelay={8}
                transition={1}
                duration={8}
                callbacks={{
                    onChange: (_, index) => {
                        setCurrentIndex(index);
                    },
                }}
            />
            {renderCaption()}
        </View>
    );
};

export default HomeSlider;
