/* eslint-disable react-hooks/exhaustive-deps */

import React from 'react';
import { View } from 'src/Widgets';
import * as Styles from './Styles.module.scss';
import Animated from '../Animated';
import { StaticImage } from 'gatsby-plugin-image';

interface IPageHeader {
    title: string;
}

const PageHeader: React.FC<IPageHeader> = props => {
    const { title } = props;

    return (
        <View className={Styles.mainContainer} dim>
            <StaticImage
                src={'../../Resources/Images/header.jpeg'}
                style={{
                    height: '100%',
                    width: '100%',
                    objectFit: 'cover',
                }}
                alt=""
            />
            <View className={Styles.titleContainer}>
                <Animated className={Styles.title} animation="fadeIn">
                    {title}
                </Animated>
            </View>
        </View>
    );
};

export default PageHeader;
