// extracted by mini-css-extract-plugin
export const mainContainer = "Styles-module--mainContainer--BLM7n";
export const container = "Styles-module--container--3oMJw";
export const containerOverlay = "Styles-module--containerOverlay--3NjgR";
export const logo = "Styles-module--logo--xaY26";
export const logoContainer = "Styles-module--logoContainer--1NdZR";
export const menuItemsContainer = "Styles-module--menuItemsContainer--3tASa";
export const menuItem = "Styles-module--menuItem--3fM89";
export const menuitem = "Styles-module--menuitem--ImZix";
export const hamburgerIcon = "Styles-module--hamburgerIcon--zmsdt";
export const mobileMenu = "Styles-module--mobileMenu--1R0me";
export const menuItemMobile = "Styles-module--menuItemMobile--eom7l";
export const closeIcon = "Styles-module--closeIcon--2-gcN";
export const flag = "Styles-module--flag--1myIZ";